export const settlements = [
    {
        region: "Вінниця",
        settlements: ["Вінниця", "Бар", "Жмеринка", "Могилів-Подільський", "Козятин"]
    },
    {
        region: "Тернопіль",
        settlements: ["Тернопіль", "Бережани", "Чортків", "Кременець", "Збараж"]
    },
    {
        region: "Хмельницький",
        settlements: ["Хмельницький", "Кам'янець-Подільський", "Шепетівка", "Старокостянтинів", "Нетішин"]
    },
    {
        region: "Житомир",
        settlements: ["Житомир", "Бердичів", "Коростень", "Малин", "Новоград-Волинський"]
    },
    {
        region: "Кропивницький",
        settlements: ["Кропивницький", "Олександрія", "Світловодськ", "Гайворон", "Знам'янка"]
    },
    {
        region: "Миколаїв",
        settlements: ["Миколаїв", "Первомайськ", "Вознесенськ", "Южноукраїнськ", "Очаків"]
    },
    {
        region: "Суми",
        settlements: ["Суми", "Конотоп", "Шостка", "Охтирка", "Ромни"]
    },
    {
        region: "Рівне",
        settlements: ["Рівне", "Дубно", "Костопіль", "Острог", "Здолбунів"]
    },
    {
        region: "Луцьк",
        settlements: ["Луцьк", "Ковель", "Володимир-Волинський", "Нововолинськ", "Рожище"]
    },
    {
        region: "Ужгород",
        settlements: ["Ужгород", "Мукачево", "Хуст", "Берегове", "Виноградів"]
    },
    {
        region: "Сєвєродонецьк",
        settlements: ["Сєвєродонецьк", "Лисичанськ", "Рубіжне", "Кремінна", "Новодружеськ"]
    },
    {
        region: "Маріуполь",
        settlements: ["Маріуполь", "Волноваха", "Мангуш", "Сартана", "Мангуш"]
    },
    {
        region: "Херсон",
        settlements: ["Херсон", "Нова Каховка", "Генічеськ", "Скадовськ", "Каховка"]
    },
    {
        region: "Запоріжжя",
        settlements: ["Запоріжжя", "Мелітополь", "Бердянськ", "Енергодар", "Токмак"]
    },
    {
        region: "Полтава",
        settlements: ["Полтава", "Кременчук", "Миргород", "Лубни", "Горішні Плавні"]
    },
    {
        region: "Черкаси",
        settlements: ["Черкаси", "Умань", "Сміла", "Золотоноша", "Канів"]
    },
    {
        region: "Чернігів",
        settlements: ["Чернігів", "Ніжин", "Прилуки", "Новгород-Сіверський", "Бахмач"]
    },
    {
        region: "Чернівці",
        settlements: ["Чернівці", "Хотин", "Новодністровськ", "Сторожинець", "Кіцмань"]
    },
    {
        region: "Івано-Франківськ",
        settlements: ["Івано-Франківськ", "Калуш", "Коломия", "Болехів", "Яремче"]
    },
    {
        region: "Київ",
        settlements: ["Київ", "Біла Церква", "Бровари", "Вишгород", "Ірпінь"]
    },
    {
        region: "Одеса",
        settlements: ["Одеса", "Ізмаїл", "Чорноморськ", "Білгород-Дністровський", "Южне"]
    },
    {
        region: "Львів",
        settlements: ["Львів", "Дрогобич", "Стрий", "Червоноград", "Трускавець"]
    },
    {
        region: "Дніпро",
        settlements: ["Дніпро", "Кривий Ріг", "Павлоград", "Нікополь", "Новомосковськ"]
    },
    {
        region: "Харків",
        settlements: ["Харків", "Чугуїв", "Лозова", "Ізюм", "Куп'янськ"]
    },
    {
        region: "Донецьк",
        settlements: ["Донецьк", "Горлівка", "Макіївка", "Єнакієве", "Шахтарськ"]
    },
    {
        region: "Луганськ",
        settlements: ["Луганськ", "Алчевськ", "Красний Луч", "Свердловськ", "Антрацит"]
    }
];
