export const regions = [
    "Житомир",
    "Закарпаття",
    "Запоріжжя",
    "Вінниця",
    "Полтава",
    "Черкаси",
    "Чернігів",
    "Чернівці",
    "Івано-Франківськ",
    "Тернопіль",
    "Хмельницький",
    "Кропивницький",
    "Миколаїв",
    "Суми",
    "Рівне",
    "Луцьк",
    "Ужгород",
    "Сєвєродонецьк",
    "Маріуполь",
    "Херсон",
    "Київ",
    "Львів",
    "Одеса",
    "Дніпро",
    "Харків",
];
